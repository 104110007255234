import React from "react"
import SEO from "components/seo"
import Layout from "components/layout"
import Intro from "components/sections/Intro"
import About from "components/sections/About"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Intro />
    <About />
  </Layout>
)

export default IndexPage
