import React from "react"
import Section from "components/section"
import H1 from "components/h1"
import Paragraph from "components/paragraph"

const About = () => (
  <Section row={false} height={"35vh"}>
    <div className="mx-auto center">
      <H1 color="text-folio-gray">
        Who am I?{" "}
        <span role="img" aria-label="thinking">
          🤔
        </span>
      </H1>
      <Paragraph color="text-folio-gray text-opacity-70">
        {/* TODO: the actual text, maybe implement multiple versions (short,
        long, etc.) */}
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Error doloribus
        numquam mollitia ad ullam blanditiis expedita amet fugit. Magnam
        delectus in optio exercitationem veniam laudantium non illo reiciendis
        inventore quae!
      </Paragraph>
    </div>
  </Section>
)

export default About
