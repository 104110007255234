import React from "react"
import PropTypes from "prop-types"

const H1 = ({ color, children }) => (
  <h1
    className={`xl:text-6xl lg:text-4xl sm:text-3xl text-2xl font-normal tracking-tight ${color} mb-4`}
  >
    {children}
  </h1>
)

H1.defaultProps = {
  color: "text-folio-white",
}

H1.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default H1
