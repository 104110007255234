import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => (
  <div className="w-full max-w-max mx-auto bg-folio-white" role="main">
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
