import React from "react"
import PropTypes from "prop-types"

const Paragraph = ({ color, children }) => (
  <p
    className={`xl:text-2xl lg:text-2xl sm:text-xl text-lg tracking-wider ${color}`}
  >
    {children}
  </p>
)

Paragraph.defaultProps = {
  color: "text-gray-600 text-opacity-100",
}

Paragraph.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Paragraph
