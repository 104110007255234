import React from "react"
import Paragraph from "components/paragraph"
import H1 from "components/h1"
import Section from "components/section"
import avatar from "images/avatar.svg"

const Intro = () => (
  <Section bgColor="bg-folio-gray" topRounded={false} height="75vh">
    <div className="order-2 md:order-1">
      <H1>
        Hi there peeps
        <span role="img" aria-label="hey">
          👋
        </span>
      </H1>
      <Paragraph color="text-folio-white text-opacity-60">
        I'm <span className="font-bold text-blue-50">João</span>, a software
        engineer based in Portugal who loooves{" "}
        <span className="font-bold text-yellow-300" style={{}}>
          Javascript
        </span>{" "}
        in general, especially{" "}
        <span className="font-bold text-blue-300">React</span> &{" "}
        <span className="font-bold text-green-300">Node</span>
        <br /> Below you'll be able to learn more about me and my work, both job
        related and open-source as well
      </Paragraph>
    </div>
    <div className="order-1 md:order-2">
      <img
        src={avatar}
        className="mx-auto w-7/12 sm:w-7/12 lg:w-full xl:w-11/12"
        alt="Avatar"
      />
    </div>
  </Section>
)

export default Intro
