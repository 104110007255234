import React from "react"
import PropTypes from "prop-types"

const Section = ({ bgColor, topRounded, height, row, children }) => (
  <section
    style={{ height }}
    className={`mx-0 md:mx-5 lg:mx-16 ${
      !topRounded ? "md:rounded-b-3xl" : "md:rounded-3xl"
    } overflow-hidden flex md:flex-row items-center justify-items-center ${bgColor} bg-opacity-100`}
  >
    <div
      className={`lg:p-14 sm:p-10 p-5 grid ${
        row
          ? "md:grid-cols-2 lg:grid-flow-row"
          : "md:grid-cols-1 md:grid-flow-col"
      } sm:gap-20 md:gap-28 gap-10 grid-cols-1 items-center`}
    >
      {children}
    </div>
  </section>
)

Section.defaultProps = {
  topRounded: true,
  bgColor: "bg-transparent",
  row: true,
}

Section.propTypes = {
  bgColor: PropTypes.string,
  topRounded: PropTypes.bool,
  height: PropTypes.string,
  row: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Section
